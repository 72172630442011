exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-asca-js": () => import("./../../../src/pages/products/asca.js" /* webpackChunkName: "component---src-pages-products-asca-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-online-ticketing-js": () => import("./../../../src/pages/products/online-ticketing.js" /* webpackChunkName: "component---src-pages-products-online-ticketing-js" */),
  "component---src-pages-products-time-and-attendance-js": () => import("./../../../src/pages/products/time-and-attendance.js" /* webpackChunkName: "component---src-pages-products-time-and-attendance-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-3-d-modeling-js": () => import("./../../../src/pages/projects/3d-modeling.js" /* webpackChunkName: "component---src-pages-projects-3-d-modeling-js" */),
  "component---src-pages-projects-agroveles-js": () => import("./../../../src/pages/projects/agroveles.js" /* webpackChunkName: "component---src-pages-projects-agroveles-js" */),
  "component---src-pages-projects-blockchain-wallet-js": () => import("./../../../src/pages/projects/blockchain-wallet.js" /* webpackChunkName: "component---src-pages-projects-blockchain-wallet-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-panagent-js": () => import("./../../../src/pages/projects/panagent.js" /* webpackChunkName: "component---src-pages-projects-panagent-js" */),
  "component---src-pages-projects-portal-pis-js": () => import("./../../../src/pages/projects/portal-pis.js" /* webpackChunkName: "component---src-pages-projects-portal-pis-js" */),
  "component---src-pages-projects-real-estate-js": () => import("./../../../src/pages/projects/real-estate.js" /* webpackChunkName: "component---src-pages-projects-real-estate-js" */),
  "component---src-pages-projects-restaurant-reservations-js": () => import("./../../../src/pages/projects/restaurant-reservations.js" /* webpackChunkName: "component---src-pages-projects-restaurant-reservations-js" */),
  "component---src-pages-projects-trampoline-park-js": () => import("./../../../src/pages/projects/trampoline-park.js" /* webpackChunkName: "component---src-pages-projects-trampoline-park-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

